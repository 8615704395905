import {ILocString} from '@shared/models/iLocalization';

export interface IGL {
}

export enum InterfaceTypeEnum {
  Sap = 1,
  BusinessCentral = 2,
  SageIntacct = 3
}

export interface IGLinterfaceList {
  a: number; // ID_GL_INTERFACE
  b: string; //INTERFACE_CODE
  c: number;  //COMPANY_INTERFACE_FLG
  d: number;    //DIRECT_FLG
  e: number;     //PREDEFINED_FLG
  f: number;   //ID_GL_INTERFACE_LAYOUT
  g: string;  //FILE_NAME
  i: InterfaceTypeEnum  // INTERFACE_TYPE_ID
  ic: string; // INTERFACE_CREDENTIALS_JSON
  l: Date//BATCH_DATE
  m: Date; //EXPORTED_DATE
  n: number;  //ID_USER
  p: number; //EXPRT_WITH_DASH_FLG
  o: string; //INTERFACE_DESCRIPTION
}

export interface IUpdateCompanyInterfaceFlg {
  id: number; //ID_GL_INTERFACE
  flg: number; //COMPANY_INTERFACE_FLG
}

export interface ILayout {
  a: number; //ID_GL_INTERFACE_LAYOUT
  b: string; //LAYOUT_NAME
  c: string; //LAYOUT_DESCRIPTION
}

export interface IParamType {
  a: number;//ID_GL_PARAM_TYPE
  b: string; //GL_PARAM_TYPE_CODE
  c: string; //GL_PARAM_TYPE_DESCRIPTION
}

export interface IInterfaceParameter {
  a: number; //ID_GL_INTERFACE_PARAMETER
  b: number; //ID_GL_INTERFACE
  c: number;//ID_GL_PARAM_TYPE
  d: string; //PARAMETER_CODE
  e: number; //LENGTH
  f: string; //VALUE
  g: number; //USED
  h: number; //RESET
  i: string; //PARAMETER_DESCRIPTION
}

export interface INewGLInterface {
  code: string;
  com_int_flg: number;
  dir_flg: number;
  predfnd_flg: number;
  id_int_layout: number;
  file_name: string;
  i: InterfaceTypeEnum  // INTERFACE_TYPE_ID
  ic: string; // INTERFACE_CREDENTIALS_JSON
  btch_date: Date;
  exprtd_date: Date;
  id_usr: number;
  exprt_with_dash_flg: number;
  des: string;
  interfaceKind: number;
}

export interface IUpdateGLInterface {
  id: number;
  code: string;
  com_int_flg: number;
  dir_flg: number;
  predfnd_flg: number;
  id_int_layout: number;
  file_name: string;
  i: InterfaceTypeEnum  // INTERFACE_TYPE_ID
  ic: string; // INTERFACE_CREDENTIALS_JSON
  btch_date: Date;
  exprtd_date: Date;
  id_usr: number;
  exprt_with_dash_flg: number;
  des: string;
}

export interface INewInterfaceParameter {
  id: number;
  gl_int_id: number;
  param_typ_id: number;
  param_code: string;
  length: number;
  value: string;
  used: number;
  reset: number;
  des: string;
}

export interface IPayrollCategoryGLAccount {
  i: number;   // CAT_DTL_CODE
  t: number;  // CAT_ERNDEDCON
  a: number; // AT_DTL_IS_ACTIVE_FLG
  d: string; // Code + Name
  l: number;   // EYR_LEVEL
  ef: number;  // CAT_DTL_PAID_BY_EYR_FLG
  ID: number;   // [dbo].[PM_PR_GL_ACCOUNTS].[ID]
  cr: string; // GL_CR_ACC
  db: string; // GL_DB_ACC
  dba: number;  // is GL_DB_ACC editable
  cra: number;  // is GL_CR_ACC editable
  touched: boolean;
  flag: boolean;
}

export interface IPayrollCategoryEmpGLAccount {
  i: number;   // CAT_DTL_CODE
  t: number;  // CAT_ERNDEDCON
  a: number; // AT_DTL_IS_ACTIVE_FLG
  d: string; // Code + Name
  l: number;   // EYR_LEVEL
  ef: number;  // CAT_DTL_PAID_BY_EYR_FLG
  ID: number;   // [dbo].[PM_PR_GL_ACCOUNTS].[ID]
  cr: string; // GL_CR_ACC
  db: string; // GL_DB_ACC
  dba: number;  // is GL_DB_ACC editable
  cra: number;  // is GL_CR_ACC editable
  ALLOCATION_TYPE: number; // PM_PR_GL_EMPLOYEE_GROUP_CATEGORIES.ALLOCATION_TYPE
  touched: boolean;
  flag: boolean;
}


export interface IEmployeeGLAccount {
  id: number; // ID_EMPLOYEE
  name: string; // Employee Number + Employee Name
  flag: boolean;
  touched: boolean;
}

export interface IDepartmentGLAccount {
  i: number;    // ID
  n: string; // Code + Name
  cr: string; // GL_CR_ACC
  db: string; // GL_DB_ACC
  touched: boolean;
}

export interface IGLPaycard {
  a: string; //employee formatted name
  b: string;  //employee Number
  c: Date; //PROC_FROM_DT
  d: Date; //PROC_TO_DT
  e: number; //PROC_SEQ_NO
  f: number; //PROC_YEAR
  g: number;//ID_PAYCARD
  h: number; //GL_IS_EXPORTED
  i: number; //GL_IS_CREATED
  j: number; //GL_IS_BALANCED
  k: number; //IS PAYCARD IN PM_PR_GL_RECORDS
  l: number; //PRLG_CODE
  m: number; //ID_EMPLOYEE
  n: number; // PROC_IS_LOCK_FLG
  pd: Date; //PROC_PAYMENTDATE
  flg: boolean;
}

export interface IReversePaycard {
  a: string; //employee formatted name
  b: string;  //employee Number
  c: Date; //PROC_FROM_DT
  d: Date; //PROC_TO_DT
  e: number; //PROC_SEQ_NO
  f: number; //PROC_YEAR
  g: number;//ID_PAYCARD
  l: number; //PRLG_CODE
  m: number; //ID_EMPLOYEE
  n: number; // PROC_IS_LOCK_FLG
  pd: Date; //PROC_PAYMENTDATE
  ps: number; //PAYCARD_STATUS
  rid: number; //REVERSED_PAYCARD_ID
  pc_origin: number;  //PAYCARD_ORIGIN
  taxprov: string;
  flg: boolean;
}


export class IGlRecord {
  a: number; //ID
  b: number; //ID_PAYCARD
  c: number; //ID_EMPLOYEE
  d: number; //CAT_DTL_CODE
  e: number; //GL_DROP_SEG1
  f: number; //GL_DROP_SEG2
  g: number;  //GL_DROP_SEG3
  h: number; //GL_DROP_SEG4
  i: number;  //GL_DROP_SEG5
  j: number; //GL_DROP_SEG6
  k: number; //GL_DEPARTMENT
  l: number; //GL_TEXT1
  m: number; //GL_TEXT2
  n: number; //GL_TEXT3
  o: number; //GL_TEXT4
  p: number; //GL_CAT_GROUP_ID
  q: number; //GL_EMP_GROUP_ID
  r: number; //GL_IS_CREDIT_FLG
  s: string; //GL_ACCOUNT
  u: number; //UNITS
  v: number; //AMOUNT
  w: string; //PC_DESCRIPTION
  x: string; //BATCH_DATE
  y: number; //PROC_YEAR
}

export interface IGlRecordNew {
  ID_PAYCARD: number;
  ID_EMPLOYEE: number;
  SECTIONID: number;
  BOLDFLAG: number;
  CAT_DTL_DISPLAY_ORDER: number;
  ITEM: string;
  GL_ACCOUNT: string;
  GL_PL_DESCRIPTION: string;
  AMOUNT: number;
  GL_IS_CREDIT_FLG: number;

}


export interface IGlPayrollGroup {
  a: number; //PRLG_CODE
  b: string; //PRLG_USER_CODE
  c: string; //PRLG_NAME
}

export interface GLFilterModel {
  payrollGroupId?: number;
  exported?: number;
  filterBy?: number;
  periodFrom?: Date;
  periodTo?: Date;
  payDateFrom?: Date;
  payDateTo?: Date;
  empDep: number;
  emploc: number;
  paycardDep: number;
  paycardloc: number;
}

export interface IGlQueueRecord {
  id_paycard: number;
  id_emp: number;
  year: number;
  proc_mode: number;
  sess_id: string;
  user: string;
  status_flg: number;
  usr_cul: string;
}

export interface IGlExportQueueRecord {
  id_paycard: number;
  id_emp: number;
  sess_id: string;
  user: string;
  usr_cul: string;
}

export interface IGLStructure {
  a: number; //GL_CAT_GROUP_ID
  b: number; //GL_CAT_GROUP_IS_ACTIVE_FLG
  c: number;  //GL_CAT_GROUP_DISTRIBUTION_TYPE
  d: string; //GL_CAT_GROUP_DESCRIPTION
  GL_CAT_DAILY_ALLOCATION_FLG: number;
  cats: string; //CAT_DTL_USER_CODEs
  catIds: string; //CAT_DTL_CODE
  credit: string;
  debit: string;
  creditIds: string; //GL_PICK_LIST_ID
  debitIds: string; //GL_PICK_LIST_ID
  e: string; //GL_PL_DESCRIPTION
}

export interface IGLEmployeeStructure {
  a: number; // GL_EMP_GROUP_ID
  b: number; // GL_EMP_GROUP_IS_ACTIVE_FLG
  c: string; // GL_EMP_GROUP_DESCRIPTION
  cats: string; //CAT_DTL_USER_CODEs
  catIds: string; //CAT_DTL_CODE
  credit: string;
  debit: string;
  creditIds: string; //GL_PICK_LIST_ID
  debitIds: string; //GL_PICK_LIST_ID
  emps: string; // Employee Number
  empIds: string; // ID_EMPLOYEE
}

export interface IAllocatedPaycat {
  GL_CAT_GROUP_ID: number;
  CAT_DTL_CODE: number;
  EYR_LEVEL: boolean;
}

//export interface IGLStructureModified {
//  a: number; //GL_CAT_GROUP_ID
//  b: number; //GL_CAT_GROUP_IS_ACTIVE_FLG
//  c: number;  //GL_CAT_GROUP_DISTRIBUTION_TYPE
//  d: string; //GL_CAT_GROUP_DESCRIPTION
//  cats: string; //CAT_DTL_USER_CODEs
//  credit_list: string;
//  debit_list: string;
//}

export interface IPicklist {
  e: string; //GL_PL_DESCRIPTION
  f: number; //GL_IS_CREDIT_FLG
  g: number; //GL_SEGMENT_ORDER
}

export interface IActiveFlgUpdate {
  gl_cat_grp_id: number;
  flag: number;
}

export interface IStructureDropdown {
  ID: number;
  GL_PL_TYPE: number;
  GL_PL_CODE: string;
  GL_PL_DESCRIPTION: string;
}
export interface IPaycatDtlCode {
  GL_CAT_GROUP_ID: number;
  a: number; //CAT_DTL_CODE
  l: boolean; //EYR_LEVEL
}
export interface IPaycatDtlCodeEmp {
  GL_EMP_GROUP_ID: number;
  ALLOCATION_TYPE: number;
  a: number; //CAT_DTL_CODE
  l: boolean; //EYR_LEVEL
}
export interface IEmpDtlCode {
  GL_EMP_GROUP_ID: number;
  ID_EMPLOYEE: number;
  IS_ACTIVE_PAYROLL_FLG: number;
}

export interface IInsertCatGroupSeg {
  id: number;
  flg: number;
  picklistId: number;
  order: number;
}

export interface IGlCatGroupCategories {
  id: number;
  cat_dtl_code: number;
  eyr_level: boolean;
}

export interface IStructureEdit {
  id: number;
  active_flg: number;
  dis_type: number;
  des: string;
  alloc_flg: number;
}
export interface IStructureEditObject {
  s: IStructureEdit;
  cats_to_insert: IGlCatGroupCategories[];
  segs_to_insert: IInsertCatGroupSeg[];
  grp_acc: IGLGroupAccount[];
  grpAllocated: IAllocatedPaycat[];
}

export interface IEmpStructureEditObject {
  s: IEmpStructureEdit;
  cats_to_insert: IGlEmpGroupCategories[];
  employees_to_insert: IGlEmpGroupEmployees[];
segs_to_insert: IInsertCatGroupSeg[];
  allocated_to_insert: IEmpGroupAllocated[];
    }
export interface IEmpGroupAllocated {
  GL_EMP_GROUP_ID: number;
  CAT_DTL_CODE: number;
  EYR_LEVEL: boolean;
  GL_IS_CREDIT_FLG: number;
  ALLOCATION_VALUE: number;
  ALLOCATION_ORDER: number;
  ID_DIMENSION1: number;
  ID_DIMENSION2: number;
  ID_DIMENSION3: number;
  ID_DIMENSION4: number;
  ID_DIMENSION5: number;
  ID_DIMENSION6: number;
  ID_DEPARTMENT: number;
  ID_LOCATION: number;
  TEXT1: string;
  TEXT2: string;
  TEXT3: string;
  TEXT4: string;
    }
export interface IGlEmpGroupEmployees {
  ID: number;
  ID_EMPLOYEE: number;
}
export interface IEmpStructureEdit {
  id: number;
  active_flg: number;
  des: string;
}
export interface IGlCatGroupCategories {
  id: number;
  cat_dtl_code: number;
  eyr_level: boolean;
}
export interface IGlEmpGroupCategories {
  id: number;
  cat_dtl_code: number;
  eyr_level: boolean;
  allocation_type: number;
 }

export interface IGlCreateOutput {
  a: number; //PROC_OUT_SEQ_NO
  b: number; //ID_PAYCARD
  c: number; // PROC_OUT_TYPE
  d: string; //PROC_OUT_MSG
}

export interface IEmployeeIdentification {

  ID: number;
  GL_PL_TYPE: number;
  GL_PL_CODE: string;
  GL_PL_DESCRIPTION: string;
}

export interface IGLExportDimensionList {
  ID: number;
  GL_PL_TYPE: number;
  GL_PL_CODE: string;
  GL_PL_DESCRIPTION: string;
}


export interface IGlExport {
  interfaceId: number;
  procSessionID: string;
}

export interface IOutput {
  LINE: string;
}


export interface IGlExportOutput {
  paycard_count: number;
  output: IOutput[];
}
export interface ICertifiedPayrollPaycardExportOutput {
  msg: string;
  output: IOutput[];//IOutput[];
}
export interface IGlExportQueueReturn {
  result: string;
  user: string
}
export interface ILaborSetupGL {
  LABOR_DIM_ID: number;
  LABOR_DIM_TYPE: number;
  LABOR_DIM_IS_ACTIVE_FLG: number;
  LABOR_DIM_PARENT_CHILD: number;
  LABOR_DIM_IS_RATE_FLG: number;
  LABOR_DIM_IS_ASSIGNED_TO_EMP_FLG: number;
  LABOR_DIM_IS_ADDED_NEW_FLG: number;
  LABOR_DIM_IS_CODE_REUSED_FLG: number;
  LABOR_DIM_UNION_AGREEMENT: number;
  LABOR_DIM_DESCRIPTION: string;
  LABOR_DIM_CAPTION: string;
  children: number;

}

export interface IDimensionForGlSetup {
  ID_DIMENSION: number;
  LABOR_DIM_ID: number;
  DIM_IS_ACTIVE_FLG: number;
  DIM_NAME: string;
  DIM_RATE: number;
  DIM_DESCRIPTION: string;
  DIM_PARENT_ID: number;
  LABOR_DIM_PARENT_CHILD: number;
  list: string;
  is_union: number;
  CP_IS_CERTIFIED_FLG: number;
  LABOR_DIM_UNION_AGREEMENT: number;
}

export interface IDimensionArry {
  list1: IDimensionForGlSetup[];
  list2: IDimensionForGlSetup[];
  list3: IDimensionForGlSetup[];
  list4: IDimensionForGlSetup[];
  list5: IDimensionForGlSetup[];
  list6: IDimensionForGlSetup[];
  dim_array_index: number;
}
export interface ILocationListGlSetup {
  ID: number;
  ParentID: number;
  Code: string;
  Name: string;
  Description: string;
  IsEnabled: boolean;
  GL_CR_ACC: string;
  GL_DB_ACC: string;
  edited: boolean;
}

export interface IEmployeeListForGlSetup {
  ID_EMPLOYEE: number;
  name: string;
  Number: string;
}
export interface IEmployeeListForRiversePaycard {
  ID_EMPLOYEE: number;
  name: string;
  PRLG_CODE: number;
  Number: string;
  province: string;
}
export interface IGlSetupOverwriteList {
  ID: number;
  CAT_DTL_CODE: number;
  EYR_LEVEL: boolean;
  GL_CR_ACC: string;
  GL_DB_ACC: string;
  ID_EMPLOYEE: number;
  ID_DIMENSION1: number;
  ID_DIMENSION2: number;
  ID_DIMENSION3: number;
  ID_DIMENSION4: number;
  ID_DIMENSION5: number;
  ID_DIMENSION6: number;
  ID_DEPARTMENT: number;
  ID_LOCATION: number;
  TEXT1: string;
  TEXT2: string;
  TEXT3: string;
  TEXT4: string;
  debit: number;
  credit: number;
  overwrite_cat: IPayrollCategoryGLAccount;
}
export interface IGlSetupCheckList {
  CAT_DTL_CODE: number;
  ID_EMPLOYEE: number;
  ID_DIMENSION1: number;
  ID_DIMENSION2: number;
  ID_DIMENSION3: number;
  ID_DIMENSION4: number;
  ID_DIMENSION5: number;
  ID_DIMENSION6: number;
  ID_DEPARTMENT: number;
  ID_LOCATION: number;
  TEXT1: string;
  TEXT2: string;
  TEXT3: string;
  TEXT4: string;
  EYR_LEVEL: number;
}

export interface IGlCustomSegmentList {
  ID: number;
  GL_PL_TYPE: number;
  GL_PL_CODE: string;
  GL_PL_DESCRIPTION: string;
  edited: boolean;
}

export interface IGLGroupAccount {
  GL_CAT_GROUP_ID: number;
  GL_IS_CREDIT_FLG: number;
  GL_DIMENSION1: number;
  GL_DIMENSION2: number;
  GL_DIMENSION3: number;
  GL_DIMENSION4: number;
  GL_DIMENSION5: number;
  GL_DIMENSION6: number;
  GL_DEPARTMENT: number;
  GL_LOCATION: number;
  GL_TEXT1: number;
  GL_TEXT2: number;
  GL_TEXT3: number;
  GL_TEXT4: number;
}
export interface IGLDimensionTab {
  ID_DIMENSION: number;
  LABOR_DIM_ID: number;
  DIM_IS_ACTIVE_FLG: number;
  DIM_NAME: string;
  DIM_RATE: number;
  GL_CR_ACC: string;
  GL_DB_ACC: string;
  DIM_DESCRIPTION: string;
  LABOR_DIM_PARENT_CHILD: number;
  parents: string;
  edited: boolean;
}

export interface IGLsetupDimension {
  ID_DIMENSION: number;
  GL_CR_ACC: string;
  GL_DB_ACC: string;
}

export interface IGLLocationUpdate {
  ID: number;
  GL_CR_ACC: string;
  GL_DB_ACC: string;
}

export interface IEmployeeListForGlSetupEmpTab {
  ID_EMPLOYEE: number;
  Name: string;
  Number: string;
  IS_ACTIVE_PAYROLL_FLG: number;
  GL_DB_ACC: string;
  GL_CR_ACC: string;
  edited: boolean;
}


export interface ICategoryForCatCondition {
  CAT_DTL_CODE: number;
  CAT_DTL_USER_CODE: string;
  CAT_DTL_PAID_BY_EYR_FLG: number;
  CAT_DTL_NAME: string;
}


export interface ILaborCategoryCondition {
  ID: number;
  CAT_DTL_CODE: number;
  EXCLUDED_CAT_DTL_CODE: number;
  ID_DIMENSION1: number;
  ID_DIMENSION2: number;
  ID_DIMENSION3: number;
  ID_DIMENSION4: number;
  ID_DIMENSION5: number;
  ID_DIMENSION6: number;
  ID_DEPARTMENT: number;
  ID_LOCATIO: number;
  TEXT1: string;
  TEXT2: string;
  TEXT3: string;
  TEXT4: string;
  edited: boolean;
}
export interface IEmpsPaystubHistory {
  name: string;
  frm_date: Date;
  to_date: Date;
  proc_seq_no: number;
  prc_year: number;
  paycrd_id: number;
  prlg_code: number;
  paystb_by_mail: boolean;
  passwrd: string;
  emp_id: number;
  paymnt_date: Date;
  chq_no: number;
  emp_no: string;
  comp_email: string;
  p_email: string;
  pdf_available: number;
  flg: number;
}

export interface IUpdateCPQueueWithNewSessId {
  rprted_frm_dt: Date;
  rprted_to_dt: Date;
  sess_id: string;
  new_sess_id: string;
}

export interface IUpdateCodeFinderCharacters {
  UseCharactersFlg: number;
  UseCharactersFrom: number;
  UseCharactersTo: number;
}

export interface ICodeFinder {
  GlAccount: string;
  GlDescription: string;
}

export interface IConnectionResult {
  WasSuccessful: boolean;
  Message: string;
  ErrorMessage: ILocString;
}

export interface IGlEmpGroupAllocation {
  GL_EMP_GROUP_ID: number;
  CAT_DTL_CODE: number;
  EYR_LEVEL: boolean;
  GL_IS_CREDIT_FLG: number;
  ALLOCATION_VALUE: any;
  ALLOCATION_ORDER: number;
  ID_DIMENSION1: number;
  ID_DIMENSION2: number;
  ID_DIMENSION3: number;
  ID_DIMENSION4: number;
  ID_DIMENSION5: number;
  ID_DIMENSION6: number;
  ID_DEPARTMENT: number;
  ID_LOCATION: number;
  TEXT1: string;
  TEXT2: string;
  TEXT3: string;
  TEXT4: string;

  Code: string;
}

export interface ISapConfiguration
{
  Url: string;
  Company: string;
  User: string;
  Password: string;
}

export interface IBusinessCentralConfiguration {
  TenantId: string;
  Company: string;
  ProductionOrSandbox: string;
}

export interface ISageIntacctConfiguration
{
  EndpointUrl: string;
  CompanyId: string;
  UserId: string;
  Password: string;
  EntityId: string;
  UdfIntegrationName: string;
}


